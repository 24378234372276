@font-face {
  font-family: Albert Sans;
  src: url("AlbertSans.7f0c28c1.woff2");
}

body {
  color: #000;
  background-color: #fff;
  margin: 20px;
  font: 16px Albert Sans, sans-serif;
  overflow-x: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: #000;
  text-decoration: none;
}

img {
  -webkit-user-select: none;
  user-select: none;
}

#modal {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 200;
  cursor: zoom-out;
  background-color: #fff;
  padding: 20px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#modal.active {
  display: block;
}

body.modal-open {
  overflow: hidden;
}

#modaled-image {
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-image:hover {
  cursor: zoom-in;
}

.modal-image.active:hover {
  cursor: zoom-out;
}

nav {
  z-index: 30;
  text-align: right;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 20px;
  margin-right: 20px;
  display: inline-block;
  position: fixed;
  bottom: 20px;
  left: 20px;
}

nav a {
  text-transform: uppercase;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 100px;
  margin-top: 6px;
  padding: 6px 12px;
  transition: background-color .25s;
  display: table;
}

nav a.active {
  color: #fff;
  background-color: #000;
}

.flower-wrapper .flower-image {
  z-index: -10;
  height: 50%;
  position: fixed;
  top: 20px;
  left: -25%;
}

.petal-wrapper {
  z-index: 10;
  cursor: move;
  -webkit-user-select: none;
  user-select: none;
  transition: transform 1s ease-in-out;
  position: fixed;
}

.petal-image {
  height: 18px;
  transform: scaleX(-1);
}

.home-wrapper {
  touch-action: none;
  width: calc(100% - 40px);
  -webkit-user-select: none;
  user-select: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-logo {
  width: calc(90% - 40px);
  margin: 0 auto;
}

.home-logo img {
  width: 100%;
  max-height: 180px;
}

.home-keywords {
  box-sizing: border-box;
  text-align: center;
  max-width: 300px;
  margin: 20px auto 0;
  padding: 0 20px;
  font-size: 16px;
  bottom: 150px;
}

.home-keywords .keyword {
  padding: 6px;
}

.about-introduction p {
  margin: 0;
}

.about-links {
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: wrap;
  margin-top: 20px;
  display: flex;
}

.about-links .about-link {
  width: 50%;
  margin-top: 20px;
}

.about-links .about-link a {
  text-underline-offset: 3px;
  text-decoration: underline;
}

.about-education .about-education-header {
  text-transform: uppercase;
}

.about-education li {
  margin: 20px 0;
}

.about-contact {
  text-align: center;
  margin: 100px 0 20px;
  font-size: 18px;
}

.about-name {
  text-transform: uppercase;
  text-align: center;
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
}

.about-keywords {
  text-align: center;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  margin: 0 auto;
  padding: 0 20px 150px;
  font-size: 18px;
}

.about-keywords .keyword {
  padding: 6px;
}

.credits-design, .credits-dev {
  font-size: 16px;
}

.commissioned-projects {
  padding-bottom: 250px;
  display: inline-block;
}

.commissioned-project {
  margin-bottom: 20px;
}

.commissioned-project p {
  margin: 0;
}

.commissioned-project-title {
  text-transform: uppercase;
  font-size: 18px;
}

.commissioned-project.active {
  font-weight: bold;
}

.commissioned-single {
  height: 100%;
  box-sizing: border-box;
  min-height: calc(100% - 40px);
  width: 100%;
  z-index: 30;
  background-color: #fff;
  padding: 80px 20px 150px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.commissioned-single .commissioned-project {
  padding-bottom: 30px;
}

.commissioned-single::-webkit-scrollbar {
  width: 0;
}

.commissioned-item {
  max-width: 75%;
  z-index: 20;
  justify-content: center;
  display: flex;
  position: relative;
}

.commissioned-item img {
  max-width: 100%;
  max-height: 70vw;
}

.commissioned-text {
  max-width: 100%;
  padding: 20px;
  display: block;
  margin: 40px 0 !important;
}

.commissioned-embed {
  z-index: 30;
}

.close-button {
  z-index: 40;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  font-size: 42px;
  line-height: .5;
  position: fixed;
  top: 20px;
}

.personal-projects {
  -webkit-user-select: none;
  user-select: none;
  padding-bottom: 150px;
}

.personal-category {
  margin-bottom: 12px;
}

.personal-category-name {
  text-transform: uppercase;
  margin-bottom: 12px;
}

.personal-category-content {
  width: 100%;
  height: 250px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.personal-category-content::-webkit-scrollbar {
  height: 5px;
}

.personal-item {
  box-sizing: border-box;
  max-height: 100%;
  z-index: 20;
  margin-right: 10px;
}

.personal-embed {
  min-width: 300px;
  max-height: 100%;
  margin-right: 10px;
}

.personal-embed iframe {
  min-width: 300px;
  max-height: 100%;
}

.personal-text.personal-item {
  min-width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  overflow-y: scroll;
}

.flex {
  flex-wrap: wrap;
  gap: 100px;
  display: flex;
}

.flex-2 {
  max-width: 400px;
  margin-bottom: 0;
}

.flex-2 p {
  margin-top: 0;
}

::selection {
  color: #fff;
  background: #000;
}

.transition-fade {
  opacity: 1;
  transition: all;
}

html.is-animating .transition-fade {
  opacity: 1;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #000;
}

@media (min-width: 768px) {
  body {
    font-size: 18px;
  }

  nav {
    left: unset;
    bottom: unset;
    margin-top: 20px;
    top: 0;
    right: 0;
  }

  nav ul {
    display: flex;
  }

  nav ul a {
    margin-top: 0;
    display: block;
  }

  nav ul a:hover {
    color: #fff;
    background-color: #000;
  }

  .flower-wrapper .flower-image {
    width: unset;
    height: 70%;
    transform: unset;
    top: 20px;
    left: -10%;
  }

  .petal-image {
    height: 90%;
  }

  .home-wrapper {
    touch-action: unset;
  }

  .home-keywords {
    max-width: 600px;
    margin-top: 40px;
    font-size: 22px;
  }

  .about-wrapper {
    width: 50%;
  }

  .about-contact, .about-name {
    font-size: 22px;
  }

  .about-keywords {
    padding-bottom: unset;
    margin-bottom: 100px;
    font-size: 22px;
  }

  .credits {
    margin-bottom: unset;
  }

  .commissioned-projects {
    max-width: 350px;
    padding-bottom: 20px;
  }

  .commissioned-single {
    padding-top: 80px;
    padding-bottom: 20px;
    padding-left: unset;
    padding-right: unset;
    left: unset;
    z-index: unset;
  }

  .commissioned-single .commissioned-project {
    display: none;
  }

  .commissioned-item {
    max-width: 50%;
  }

  .commissioned-item img {
    max-height: 30vw;
  }

  .commissioned-embed {
    width: 50%;
  }

  .commissioned-text {
    max-width: 50%;
  }

  .personal-category-content {
    height: 300px;
  }

  .personal-embed, .personal-embed iframe {
    min-width: 450px;
  }

  .personal-text.personal-item {
    min-width: 500px;
  }

  .personal-projects {
    padding-bottom: 0;
  }

  .flex-2 {
    min-width: 300px;
  }
}

@media (min-width: 1280px) {
  .flower-wrapper .flower-image {
    width: unset;
    height: 90%;
    transform: unset;
    top: 20px;
    left: -5%;
  }

  .about-wrapper {
    width: 60%;
  }

  .commissioned-single {
    width: 60%;
    height: unset;
    overflow-y: unset;
    position: absolute;
    right: 20px;
  }

  .commissioned-single .close-button {
    display: none;
  }
}

/*# sourceMappingURL=main.css.map */
