// fonts
@font-face
	font-family: 'Albert Sans'
	src: url('/public/assets/fonts/AlbertSans.woff2')

// variables
$font-family: 'Albert Sans', sans-serif
$font-size-xxl: 112px
$font-size-xl: 42px
$font-size-l: 22px
$font-size-m: 18px
$font-size-s: 16px

$color-txt: #000
$color-bg: #fff

$spacing-xxl: 240px
$spacing-xl: 100px
$spacing-l: 40px
$spacing-m: 20px
$spacing-s: 12px
$spacing-mobile-bottom: 150px

$transition-time: .25s

// styles
body
	font: $font-size-s $font-family
	color: $color-txt
	background-color: $color-bg
	margin: $spacing-m
	overflow-x: hidden

ul
	list-style: none
	padding: 0
	margin: 0

a
	text-decoration: none
	color: $color-txt

img
	user-select: none

// modal
#modal
	display: none
	position: fixed
	background-color: $color-bg
	// background-repeat: no-repeat
	// background-position: center
	// background-size: contain
	// background-origin: content-box
	width: 100%
	height: 100%
	padding: $spacing-m
	box-sizing: border-box
	top: 0
	left: 0
	z-index: 200
	cursor: zoom-out
#modal.active
	display: block

body.modal-open
	overflow: hidden

#modaled-image
	position: fixed
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	max-width: calc(100% - $spacing-m)
	max-height: calc(100% - $spacing-m)

.modal-image:hover
	cursor: zoom-in

.modal-image.active:hover
	cursor: zoom-out

// nav
nav
	z-index: 30
	position: fixed
	bottom: $spacing-m
	left: $spacing-m
	margin-top: $spacing-m
	margin-right: $spacing-m
	display: inline-block
	text-align: right
	user-select: none
	a
		text-transform: uppercase
		display: table
		border: 1px solid $color-txt
		border-radius: 100px
		padding: .5*$spacing-s $spacing-s
		margin-top: .5*$spacing-s
		background-color: $color-bg
		transition: color $transition-time
		transition: background-color $transition-time
	a.active
		background-color: $color-txt
		color: $color-bg

// flower
.flower-wrapper
	.flower-image
		z-index: -10
		height: 50%
		position: fixed
		left: -25%
		top: $spacing-m

// petals
.petal-wrapper
	position: fixed
	transition: transform 1s ease-in-out
	z-index: 10
	cursor: move
	user-select: none

.petal-image
	height: 18px
	-webkit-transform: scaleX(-1)
	transform: scaleX(-1)

// home
.home-wrapper
	touch-action: none
	position: fixed
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	width: calc(100% - $spacing-m * 2)
	user-select: none
	
.home-logo
	margin: 0 auto
	width: calc(90% - $spacing-m*2)
	img
		width: 100%
		max-height: 180px
.home-keywords
	padding: 0 $spacing-m
	box-sizing: border-box
	margin: 0 auto
	margin-top: $spacing-m
	font-size: $font-size-s
	text-align: center
	max-width: 300px
	bottom: $spacing-mobile-bottom
	.keyword
		padding: .5*$spacing-s

// about
.about-introduction
	p
		margin: 0

.about-links
	margin-top: $spacing-m
	text-transform: uppercase
	display: flex
	flex-wrap: wrap
	user-select: none
	.about-link
		margin-top: $spacing-m
		width: 50%
		a
			text-decoration: underline
			text-underline-offset: 3px

.about-education
	.about-education-header
		text-transform: uppercase
	li
		margin: $spacing-m 0

.about-contact
	font-size: $font-size-m
	text-align: center
	margin: $spacing-m 0
	margin-top: $spacing-xl

.about-name
	font-weight: bold
	text-transform: uppercase
	font-size: $font-size-m
	text-align: center
	margin: $spacing-m 0

.about-keywords
	font-size: $font-size-m
	text-align: center
	width: 100%
	max-width: 600px
	margin: 0 auto
	padding: 0 $spacing-m
	padding-bottom: $spacing-mobile-bottom
	box-sizing: border-box
	user-select: none
	.keyword
		padding: .5*$spacing-s
	
.credits-design, .credits-dev
	font-size: $font-size-s

// commissioned
.commissioned-projects
	display: inline-block
	padding-bottom: 250px

.commissioned-project
	margin-bottom: $spacing-m
	p
		margin: 0

.commissioned-project-title
	font-size: $font-size-m
	text-transform: uppercase
.commissioned-project.active
	font-weight: bold
.commissioned-single
	background-color: $color-bg
	overflow-y: scroll
	position: fixed
	height: 100%
	box-sizing: border-box
	padding-top: $spacing-l*2
	padding-bottom: $spacing-mobile-bottom
	padding-left: $spacing-m
	padding-right: $spacing-m
	min-height:	calc(100% - 2*$spacing-m)
	width:	100%
	left: 0
	top: 0
	z-index: 30
	.commissioned-project
		padding-bottom: 30px

.commissioned-single::-webkit-scrollbar
	width: 0px

.commissioned-item
	max-width: 75%
	// max-height: 400px
	position: relative
	display: flex
	justify-content: center
	z-index: 20
	img
		max-width: 100%
		max-height: 70vw

.commissioned-text
	display: block
	margin: $spacing-l 0 !important
	max-width: 100%
	padding: $spacing-m

.commissioned-embed
	z-index: 30

.close-button
	font-size: $font-size-xl
	padding: 0
	margin: 0
	line-height: .5
	position: fixed
	top: $spacing-m
	z-index: 40
	cursor: pointer
	user-select: none

// personal
.personal-projects
	padding-bottom: $spacing-mobile-bottom
	user-select: none

.personal-category
	margin-bottom: $spacing-s

.personal-category-name
	text-transform: uppercase
	margin-bottom: $spacing-s

.personal-category-content
	display: flex
	flex-wrap: nowrap
	width: 100%
	height: 250px
	box-sizing: border-box
	overflow-x: scroll
	overflow-y: hidden
	align-items: center

.personal-category-content::-webkit-scrollbar
	height: 5px

.personal-item
	box-sizing: border-box
	max-height: 100%
	margin-right: 10px
	z-index: 20

.personal-embed
	margin-right: 10px
	min-width: 300px
	max-height: 100%
	iframe
		min-width: 300px
		max-height: 100%

.personal-text.personal-item
	// background-color: red
	min-width: 100%
	box-sizing: border-box
	padding: 0 $spacing-m
	overflow-y: scroll

// utility
.flex
	display: flex
	flex-wrap: wrap
	gap: $spacing-xl
.flex-2
	margin-bottom: 0
	max-width: 400px
	p
		margin-top: 0

// selection
::-moz-selection
	color: $color-bg
	background: $color-txt

::selection
	color: $color-bg
	background: $color-txt

// swup
.transition-fade
	transition: 0s
	opacity: 1

html.is-animating .transition-fade
	opacity: 1

// scrollbar
::-webkit-scrollbar
	width: 5px
	
::-webkit-scrollbar-thumb
	background: $color-txt



@media (min-width: 768px)

	body
		font-size: $font-size-m
	
	// nav
	nav
		left: unset
		bottom: unset
		top: 0
		right: 0
		margin-top: $spacing-m
		ul
			display: flex
			a
				display: block
				margin-top: 0
			a:hover
				background-color: $color-txt
				color: $color-bg

	// flower
	.flower-wrapper
		.flower-image
			width: unset
			height: 70%
			left: -10%
			top: $spacing-m
			transform: unset

	// petals
	.petal-image
		height: 90%

	// home
	.home-wrapper
		touch-action: unset
	.home-keywords
		font-size: $font-size-l
		max-width: 600px
		margin-top: $spacing-l

	// about
	.about-wrapper
		width: 50%

	.about-contact
		font-size: $font-size-l

	.about-name
		font-size: $font-size-l

	.about-keywords
		font-size: $font-size-l
		padding-bottom: unset
		margin-bottom: $spacing-xl

	.credits
		margin-bottom: unset

	// commissioned
	.commissioned-projects
		padding-bottom: $spacing-m
		max-width: 350px

	.commissioned-single
		padding-bottom: $spacing-m
		padding-top: $spacing-l*2
		padding-left: unset
		padding-right: unset
		left: unset
		z-index: unset
		.commissioned-project
			display: none

	.commissioned-item
		max-width: 50%
		img
			max-height: 30vw

	.commissioned-embed
		width: 50%

	.commissioned-text
		max-width: 50%

	// personal
	.personal-category-content
		height: 300px

	.personal-embed
		min-width: 450px
		iframe
			min-width: 450px

	.personal-text.personal-item
		min-width: 500px
	.personal-projects
		padding-bottom: 0

	// utility

	.flex-2
		min-width: 300px



@media (min-width: 1280px)

	// flower
	.flower-wrapper
		.flower-image
			width: unset
			height: 90%
			left: -5%
			top: $spacing-m
			transform: unset

	// about
	.about-wrapper
		width: 60%

	.commissioned-single
		width: 60%
		height: unset
		right: $spacing-m
		position: absolute
		overflow-y: unset
		.close-button
			display: none
